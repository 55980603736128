export const ON_CHANGE_DEBOUNCE_TIMER = 300;

export const PUB_SUB_EVENTS = {
  cartUpdate: 'cart-update',
  quantityUpdate: 'quantity-update',
  variantChange: 'variant-change',
  cartError: 'cart-error',
};

export function headerHeight() {
  const header = document.querySelector('header');
  const announcementBar = document.querySelector('announcement-slider');
  const globaleBanner = document.querySelector('#FreeShippingBanner');
  const isAtTop = window.scrollY === 0;

  let height = 0;

  if (header) height += header.clientHeight;
  // Only include announcement bar and global banner heights when at top of page
  if (isAtTop) {
    if (announcementBar) height += announcementBar.clientHeight;
    if (globaleBanner) height += globaleBanner.clientHeight;
  }

  height = height || 64; // fallback height if no elements found

  document.documentElement.style.setProperty('--header-height', `${height}px`);
  return height;
}

// Watch for the FreeShippingBanner to be added to the DOM
const observer = new MutationObserver((mutations, obs) => {
  const banner = document.querySelector('#FreeShippingBanner');
  if (banner) {
    headerHeight(); // Update header height once banner is found
    obs.disconnect(); // Stop observing once we've found it
  }
});

observer.observe(document.body, {
  childList: true,
  subtree: true
});

// Disconnect the observer after 5 seconds if banner hasn't been found
setTimeout(() => {
  observer.disconnect();
  headerHeight(); // Set header height anyway, even without banner
}, 6000);

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty(`--app-height`, `${window.innerHeight}px`);
};
window.addEventListener(`resize`, appHeight);
appHeight();
